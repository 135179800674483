
let OrganizationType = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr
    this.Types = function (success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationType'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }
    this.OrganizationTypeByProduct = function (productId, success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationType/Product/' + productId
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }
    /**
    * ������֯��������
    * @param {any} name ��֯������������
    * @param {any} success
    * @param {any} error
    */
    this.Add = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationType'
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }
    /**
     * �༭��֯��������
     * @param {any} typeId
     * @param {any} name ��֯������������
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationType'
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }
    /**
     * �Ƴ���֯��������
     * @param {any} typeId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (typeId, success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationType/' + typeId
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }
}

export { OrganizationType }


let Product = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    
    /**
     * 查询所有产品
     * @param {any} success
     * @param {any} error
     */
    this.ProductAll = function (success, error) {
        var url = ServiceBaseAddress + '/api/Product/All';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 系统管理员查询所有产品和版本
     * @param {any} success
     * @param {any} error
     */
    this.ProductVersions = function (success, error) {
        var url = ServiceBaseAddress + '/api/Product/All/Versions';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 新增产品
     * @param {any} categoryId 产品数据分类，1：系统 2：医院
     * @param {any} roleKey 产品标志
     * @param {any} roleName 产品名称
     * @param {any} Alias 版本名称
     * @param {any} roleDescript 产品介绍
     * @param {any} success
     * @param {any} error
     */
    this.Add = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/Product';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 修改产品信息
     * @param {any} roleId
     * @param {any} categoryId 产品数据分类，1：系统 2：医院
     * @param {any} roleKey 产品标志
     * @param {any} roleName 产品名称
     * @param {any} Alias 版本名称
     * @param {any} roleDescript 产品介绍
     * @param {any} roleStatu 产品状态，1：启用 -1：禁用
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/Product';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 删除产品
     * @param {any} roleId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Product/' + id;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.exportProductData = function (param, success, error) {
      var url = ServiceBaseAddress + "/api/Product/Export";
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        param,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data);
            success(jsonObj);
          }
        },
        error
      );
    };
    this.ImportProductData = function (param, success, error) {
      var url = ServiceBaseAddress + "/api/Product/Import";
      TokenClient.Post(
        url,
        true,
        { "Content-Type": "application/json" },
        param,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data);
            success(jsonObj);
          }
        },
        error
      );
    };
}

export { Product };

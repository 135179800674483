
let Industry = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 获取所有数据分类
     * @param {any} success
     * @param {any} error
     */
    this.IndustryByProductId = function ( productId ,keyWord, success, error) {
        var url = ServiceBaseAddress + `/api/Industry/${productId}`;
        if (keyWord) {
            url += `/${keyWord}`;
        }
        else {
            url += '/%20';
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 新增数据分类
     * @param {any} IndustryName 分类名称
     * @param {any} description 分类简介
     * @param {any} success
     * @param {any} error
     */
    this.Add = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/Industry';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 修改数据分类
     * @param {any} IndustryId
     * @param {any} IndustryName 分类名称
     * @param {any} description 分类简介
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/Industry';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 删除数据分类
     * @param {any} id
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (id, success, error) {
        var url = ServiceBaseAddress + '/api/Industry/' + id;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Industry }


let OrganizationLevel = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    this.Levels = function (success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationLevel';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.LevelByProduct = function (productId , success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationLevel/Product/' + productId ;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
    * ������֯��������
    * @param {any} name ��֯������������
    * @param {any} success
    * @param {any} error
    */
    this.Add = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationLevel';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �༭��֯��������
     * @param {any} levelId
     * @param {any} name ��֯������������
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationLevel';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �Ƴ���֯��������
     * @param {any} levelId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (levelId, success, error) {
        var url = ServiceBaseAddress + '/api/OrganizationLevel/' + levelId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { OrganizationLevel }
